import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { graphql } from "gatsby";

// eslint-disable-next-line
export const DocTemplate = ({
  content,
  contentComponent,
  title,
  description,
  featuredimage,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="subPage ">
      {helmet || ""}
      <div className="image">
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          alt={title}
          title={title}
          className="post-image"
        />
        <div className="titles">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="container content">
        <div className="content" style={{ margin: "20px 20px 40px" }}>
          <PostContent content={content} />
        </div>
      </div>
    </section>
  );
};

DocTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  locale: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
};

const DocPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout locale={post.frontmatter.locale}>
      <DocTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        locale={post.frontmatter.locale}
      />
    </Layout>
  );
};

DocPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default DocPost;

export const pageQuery = graphql`
  query DocPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        locale
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
